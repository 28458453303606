import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import MyLogo from './MyLogo.png';
import {TypeAnimation} from 'react-type-animation';

function HomePage() {
    const navigate = useNavigate();
    const typeAnimationRef = useRef(null);
    const [translateX, setTranslateX] = useState(0);

    useEffect(() => {
        const updateScroll = () => {
            const element = typeAnimationRef.current;
            if (element) {
                const overflowWidth = element.scrollWidth - 600; // Adjust '600' based on your search bar width
                if (overflowWidth > 0) {
                    // Add extra space for each move
                    setTranslateX(-(overflowWidth + 50)); // 70px extra space
                } else {
                    // Reset translation when there is no overflow
                    setTranslateX(0);
                }
            }
        };

        const interval = setInterval(updateScroll, 1000); // Adjust interval as needed
        return () => clearInterval(interval);
    }, []);


    function showResume() {
        navigate('/resume');
    }

    function feelingLucky() {
        navigate('/contact');
    }

    return (
        <div className="App home">
            <header className="App-header home-header">
                <img src={MyLogo} alt="Logo" className="home-logo" />
                <div className="search-bar home-search-bar">
                    <div
                        className="type-animation-wrapper"
                        ref={typeAnimationRef}
                        style={{ transform: `translateX(${translateX}px)` }}
                    >
                        <TypeAnimation
                            cursor={true}
                            sequence={[
                                'Welcome on my portfolio website!!!!!!!!!!!!!!!!!', 3000,
                                'You are about to experience one of the best websites ever', 3000,
                                'If you are looking for best IT employee you are in correct place', 4000,
                                'You are literally on the best it employee site, if that is not enough check my resume', 4000,
                                'What are you waiting for? Just click show resume button', 4000,
                                'You can also try your luck by selecting second option, maybe it will show best solution for your problems?', 3000,
                                'Wow you got legendary patience, remember time is money lets save it a little and click on resume button', 3000,
                                'What should I write to convince you... I got cats on my website!!!', 3000,
                                'Also dogs', 2000,
                                'Cute ones', 2000,
                                'This will be last message in these animation, after it will start again.', 3000,
                                'Just joking I spent my whole free time for making this animation, that was like 60% of this website >:)', 3000,
                                'Or maybe 90%, I am not sure but you can try find out by clicking one of options below', 3000,
                                'Okey this is not funny anymore if you will persist, whole website will be destroyed!!!', 3000,
                                'And you will never achieve contact to the best IT employee (me of course)', 3000,
                                'Site will be down in:', 3000,
                                '3', 5000,
                                '2', 5000,
                                '1', 5000,
                                'You got last final option for calling for help, just call me through "I am feeling lucky button"', 3000,
                                '1 again', 3000,
                                '0.9', 3000,
                                '0.8', 3000,
                                '0.7', 3000,
                                'You know that I have 60 lines more? And everything works in infinite loop?', 3000,
                                '0.5', 3000,
                                'Ha! You did not notice that I skipped 0.6!', 3000,
                                '0.4', 3000,
                                '0.3', 3000,
                                '0.2', 3000,
                                '0.1', 3000,
                                '* Dying sounds *', 3000,
                                'Just click one of the options below', 3000,
                                '* More dying sounds *', 3000,
                                () => {
                                    console.log('Done typing!');
                                }
                            ]}
                            wrapper="div"
                            repeat={Infinity}
                            className="type-animation-wrapper"
                        />
                    </div>
                </div>
                <div className="buttons">
                    <button onClick={showResume}>Show Resume</button>
                    <button onClick={feelingLucky}>I'm Feeling Lucky</button>
                </div>
            </header>
        </div>
    );
}

export default HomePage;