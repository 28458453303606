import React from 'react';
import './ProfileSidebarContact.css';
import ProfileImg from './profile.jpg';


function ProfileSidebarContact() {
    // Dummy data for the profile, you can replace this with actual data
    const profileInfo = {
        name: "Contact info",
        occupation: "Contact with your best option - Iwo Lis",
        occupation2: "Hired",
        location: "Warsaw, Poland",
        age: "23",
        height: "1.83 m (metric system only)",
        expertise: ["Information Systems Engineering", "Project Management"],
    };


    return (
        <aside className="profile-sidebar-contact">
            <img src={ProfileImg} alt="Profile" className="contact-profile-image" />
            <div className="contact-profile-info">
                <h1 className="contact-profile-name">{profileInfo.name}</h1>
                <p className="contact-profile-detail">{profileInfo.occupation} </p>
                <div className="contact-profile-details">
                    <p><strong>E-mail : </strong> IwoLisJobs@gmail.com</p>
                    <p><strong>Phone : </strong> +48 506 267 456</p>
                    <p><strong>Localization : </strong> <a href="https://www.google.com/maps/place/Lykkens+Minde+33,+2860+S%C3%B8borg,+Denmark/@55.7421879,12.4808891,17z/data=!3m1!4b1!4m6!3m5!1s0x465251e0ac07ed89:0xe322dccc691b826a!8m2!3d55.7421849!4d12.4834694!16s%2Fg%2F11l24bymrw?entry=ttu" target="_blank" rel="noopener noreferrer">Lykkens Minde 33, 2860 Søborg, Copenhagen, Denmark</a></p>
                </div>

            </div>
            <div className="contact-social-media-section">
                <h2 className="contact-social-media-title">Social Networks</h2>
                <div>
                    <a href="https://www.facebook.com/profile.php?id=100017142606098" className="social-media-link" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://www.youtube.com" className="social-media-link" target="_blank" rel="noopener noreferrer">Youtube</a>
                    <a href="https://www.linkedin.com/in/iwo-lis-3bb6601b7/" className="social-media-link" target="_blank" rel="noopener noreferrer">Linkedin</a>
                    <a href="https://twitter.com/iwo_lis" className="social-media-link" target="_blank" rel="noopener noreferrer">X</a>
                </div>
            </div>

        </aside>
    );
}

export default ProfileSidebarContact;