import React from 'react';
import './ProfileSidebarSkills.css';
import ProfileImg from './profile3.jpg';


function ProfileSidebar() {

    const profileInfo = {
        name: "Additional info",
        occupation: "Some interesting facts about my books preferences",
    };


    return (
        <aside className="profile-sidebar">
            <img src={ProfileImg} alt="Profile" className="profile-image" />
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">{profileInfo.occupation}</p>
                <div className="profile-details">
                    <p><strong>Best school lecture : </strong> The Doll (Prus novel)</p>
                    <p><strong>Favorite history book : </strong> Grzesiuk's "Pięć lat kacetu" </p>
                    <p><strong>Childhood comic series : </strong> Duck tales </p>
                    <p><strong>Favorite LOTR character : </strong> Sam</p>
                </div>
            </div>
        </aside>
    );
}

export default ProfileSidebar;