import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebarSkills';
import './Resume.css';
import './Header.css';
import './ProfileSidebarSkills.css';
import './Skills.css';
import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar, faStar as farStar, faStarHalfAlt} from '@fortawesome/free-solid-svg-icons';




function Skills() {
    const navigate = useNavigate();
    const navigateToGoogle = () => {
        window.open('https://www.techtarget.com/searchapparchitecture/definition/object-oriented-programming-OOP', '_blank');
    };

    const navigateToPara = () => {
        window.open('https://www.visual-paradigm.com/', '_blank');
    };

    const navigateToAndroid = () => {
        window.open('https://developer.android.com/studio', '_blank');
    };

    const navigateToEko = () => {
        window.open('https://www.investopedia.com/terms/e/econometrics.asp', '_blank');
    };

    const navigateToTrends = () => {
        window.open('https://trends.google.com/trends/', '_blank');
    };

    const navigateToLeader = () => {
        window.open('https://www.walkme.com/glossary/technology-innovation-leaders/', '_blank');
    };

    const navigateToAgile = () => {
        window.open('https://www.agilealliance.org/agile101/#:~:text=Agile%20is%20the%20ability%20to,an%20uncertain%20and%20turbulent%20environment.', '_blank');
    };

    const navigateToReact = () => {
        window.open('https://react.dev/', '_blank');
    };

    const navigateToOffice = () => {
        window.open('https://www.office.com/', '_blank');
    };
    function showContact() {
        navigate('/contact');
    }

    const skillsItems = [
        {
            title: "Object-Oriented Programming",
            path: "www.techtarget.com > definition",
            description: "Most of today's modern projects are mostly created with the help of Java , C# , C++ and Python. The art is the ability to choose the right language for the project. Thanks to the experience gained while creating many solutions, I am fluent in object-oriented languages although sometimes I need a moment to switch .",
            onClick: navigateToGoogle,
            rating: 5,
            reviews: 256
        },
        {
            title: "Website Development",
            path: "https://react.dev",
            description: "\n" +
                "Internet technologies will always be very popular in our society. So, in response to this, I create and design applications and websites in React & React Native. Mainly I focus on the Node.js, but also if frontend is needed I am here ...",
            onClick: navigateToReact,
            rating: 4,
            reviews: 443
        },
        {
            title: "UML Modeling",
            path: "www.visual-paradigm.com > home",
            description: "What is application development without a good UML model? My expertise in UML, using tools like StarUML and Visual Paradigm, ensures precise system architecture and clear communication across project teams, pivotal for successful IT project outcomes.",
            onClick: navigateToPara,
            rating: 4,
            reviews: 3141
        },
        {
            title: "Mobile App Development",
            path: "https://developer.android.com > studio",
            description: "In the ever-evolving mobile landscape, versatility is key. My expertise in using Android Studio for app development allows me to bring robust and user-friendly mobile applications to life. With a deep understanding of Android's ecosystem and a keen eye for design, I craft apps that are not only functional but also intuitive, ensuring a seamless user experience across diverse devices and platforms.",
            onClick: navigateToAndroid,
            rating: 4 ,
            reviews: 64
        },
        {
            title: "Econometric Analysis",
            path: "www.investopedia.com> terms > econometrics",
            description: "Proficient in applying econometric models to analyze economic data, utilizing statistical techniques and software tools like Stata and R. My expertise in this area enables me to forecast trends, inform policy decisions, and provide strategic insights into market dynamics. This skill is essential for data-driven decision-making in economic and financial contexts. ",
            onClick: navigateToEko,
            rating: 3,
            reviews: 80
        },
        {
            title: "IT Market Analysis",
            path: "https://trends.google.com > trends",
            description: "Expert in identifying market gaps for IT solutions, utilizing tools like Statista, Google Trends, and App Annie for insightful analysis. My approach combines data-driven insights and current trends to pinpoint opportunities for innovative application development. ",
            onClick: navigateToTrends,
            rating: 4,
            reviews: 2137
        },
        {
            title: "Tech Innovation Leadership",
            path: "https://walkme.com > glossary > technology-innovation-leaders",
            description: "Specialized in leading and contributing to tech innovation through active participation in hackathons, consistently recognized for original and effective solutions. My experience in these dynamic, competitive environments has honed my skills in not just ideation but also in rapid development and collaborative problem-solving, aligning with the latest trends and technologies in the IT sector. This expertise reflects my ability to innovate under pressure and contribute valuable, cutting-edge ideas to any tech team or project.",
            onClick: navigateToLeader,
            rating: 5,
            reviews: 8020
        },

        {
            title: "Project Management",
            path: "www.agilealliance.org > agile101",
            description: "Proficient in Agile methodologies, particularly Scrum, to drive efficient and adaptive project management. Skilled in utilizing tools like JIRA for task tracking and Confluence for documentation, ensuring seamless collaboration and real-time progress tracking. This approach enhances team agility, accelerates deliverables, and optimizes project outcomes.",
            onClick: navigateToAgile,
            rating: 4,
            reviews: 574
        },

        {
            title: "And I even know how to use office softwares",
            path: "https://www.office.com",
            description: "I grew up with Microsoft Word, Excel and PowerPoint, advanced data management and great presentation are necessary first to understand and then convice...",
            onClick: navigateToOffice,
            rating: 5,
            reviews: 999
        }
    ];

    return (
        <div className="resume">
            <Header
                currentPage="Skills"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="resume-content-with-sidebar">
                <div className="resume-content">
                    {/* Small grey text similar to Google's result stats */}
                    <div className="resume-stats">
                        About 10 important skills (0.16 Century of learning)
                    </div>

                    {skillsItems.map((item, index) => (
                        <SkillsItem
                            key={index}
                            title={item.title}
                            path={item.path}
                            description={item.description}
                            onClick={item.onClick}
                            rating={item.rating}
                            reviews={item.reviews}
                        />
                    ))}
                </div>
                <ProfileSidebar />
            </div>
            <div className="extra-skills-content">

                <div className="hire-me-section">
                    <div className="hire-me-title">Hire me</div>
                    <p>We could work together?</p>
                    <button onClick={showContact} className="hire-me-button">Contact</button>
                </div>
            </div>
        </div>
    );
}

function SkillsItem({ title, path, description, onClick, rating, reviews }) {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 > 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    const pathParts = path.split(' > ');

    const handleTitleClick = (e) => {
        e.preventDefault(); // Prevents the default anchor action
        onClick(); // Calls the onClick function passed in the props
    };

    return (
        <div className="resume-item">
            <a href="#" onClick={handleTitleClick} className="resume-item-title">
                {title}
            </a>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="star-rating">
                {[...Array(fullStars)].map((_, index) => (
                    <FontAwesomeIcon key={`full-${index}`} icon={farStar} className="gold-star" />
                ))}
                {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} className="gold-star" />}
                {[...Array(emptyStars)].map((_, index) => (
                    <FontAwesomeIcon key={`empty-${index}`} icon={faStar} className="grey-star" />
                ))}
                <span className="reviews-count">{reviews} reviews</span>
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Skills;