import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebar';
import './Resume.css';
import './Header.css';
import './Experience.css';
import './ProfileSidebar.css';
import './Education.css';
import {useNavigate} from "react-router-dom";


function Experience() {
    const navigate = useNavigate();
    function showContact() {
        navigate('/contact');
    }
    function showCode() {
        window.open('https://www.codehussar.com/', '_blank');
    }
    function showOmni() {
        window.open('https://www.omnitrace.io/', '_blank');
    }
    function showGame() {
        window.open('https://www.reikongames.com/', '_blank');
    }
    function showFestiwal() {
        window.open('https://festiwalnnw.pl/en/', '_blank');
    }
    const experienceItems = [

        {
            title: "IT Project Manager",
            path: "https://www.omnitrace.io",
            description: "Since 2023 I led the development of innovative mobile and web applications, enhancing logistics and inventory management. My role involved coordinating with outsourced IT teams in India, overseeing the creation of foundational app structures. I ensured seamless integration and functionality, aligning development efforts with project timelines and quality standards, while fostering effective communication and collaboration across diverse, international teams.",
            onClick: showOmni
        },
        {
            title: "Full-Stack Developer",
            path: "https://www.codehussar.com",
            description: "2021 to 2023 - Developing mobile (Java, Android Studio, React Native, Firebase, Flutter) and web apps (Node.js, .NET, Angular, React), I conduct client interviews to capture key business needs and user requirements. My role involves pitching projects, crafting business and UML models, and managing resources to ensure timely, budget-compliant project completion.",
            onClick: showCode
        },
        {
            title: "Programmer & Game Designer",
            path: "https://www.reikongames.com",
            description: "2020 to 2021 - Developing and implementing game mechanics and gameplay features using C++. Solving selected IT tasks, listed in Jira. Maintaining and improving the website. Adding own ideas to the project and attending brainstorming events.",
            onClick: showGame
        },
        {
            title: "Event Manager Assistant",
            path: "https://festiwalnnw.pl > en",
            description: "2019 to 2020 - Cooperation in creating the NNW festival. Selecting the best catering and accomodation options. Making accreditation and service for special guests. Creating a festival schedule. Helping the main event manager in his tasks. ",
            onClick: showFestiwal
        }
    ];


    return (
        <div className="resume">
            <Header
                currentPage="Experience"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="main-content-container">
                <div className="resume-content-with-sidebar">
                    <div className="resume-content">
                        <div className="resume-stats">
                            About 168 732 000 seconds of experience (0.05 century)
                        </div>
                        <div className="hire-me-section-edu">
                            <div className="hire-me-title-edu">Your Company here</div>
                            <div className="hire-me-subtitle-edu"> Add a creative spark to your team, driving projects with passion and precision.</div>
                            <button onClick={showContact} className="hire-me-button-edu">Contact me and let's work together!</button>
                        </div>

                        {experienceItems.map((item, index) => (
                            <ExperienceItem
                                key={index}
                                title={item.title}
                                path={item.path}
                                description={item.description}
                                onClick={item.onClick}
                            />
                        ))}
                    </div>
                    <ProfileSidebar />
                </div>

            </div>
        </div>
    );
}

function ExperienceItem({ title, path, description, onClick }) {
    // Split the path by '>'
    const pathParts = path.split(' > ');

    return (
        <div className="resume-item">
            <div className="resume-item-title" onClick={onClick}>
                {title}
            </div>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Experience;