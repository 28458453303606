import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebarAwards';
import './Resume.css';
import './Header.css';
import './ProfileSidebarSkills.css';
import './Skills.css';
import {useNavigate} from "react-router-dom";


function Awards() {
    const navigate = useNavigate();

    const navigateToTele = () => {
        window.open ('https://www.gik.pw.edu.pl/Aktualnosci/Relacja-z-jubileuszowej-edycji-Dnia-Teledetekcji','_blank' );
    };
    const navigateToScholar = () => {
        window.open ('https://www.wojsko-polskie.pl/wat/en/', '_blank' );
    };
    const navigateToRector = () => {
        window.open ('https://old.wcy.wat.edu.pl/pl/news/trzecie-miejsce-w-konkursie-rektorskim-dla-zespolu-code-hussar','_blank' );
    };
    const navigateToAgile = () => {
        window.open ('https://2021.wizjarozwoju.pl/hackathon-wizja-rozwoju-21-wreczenie-nagrod-uczestnikom/','_blank' );
    };
    const navigateToReact = () => {
        window.open ( 'https://old.wcy.wat.edu.pl/pl/news/zespol-z-wcy-wygrywa-kolejny-ogolnopolski-hackathon','_blank' );
    };
    const navigateToOffice = () => {
        window.open ('https://2020.hackyeah.pl/2020/12/10/the-finalist-default-name/','_blank' );
    };
    function showContact() {
        navigate('/contact');
    }

    const skillsItems = [


        {
            title: "1st Place in National Hackathon: \"XX Edycja Dnia Teledetekcji Politechniki Warszawskiej\"",
            path: "www.gik.pw.edu.pl > Aktualnosci > Relacja-z-Dnia-Teledetekcji",
            description: "Awarded in 2023 for my presentation, where I cosplayed as a farmer, showcasing 'KAPPUSTA'. This application was designed for detecting agricultural threats and providing accurate, precise advice on garden and farm maintenance. Its functionalities leveraged remote sensing technology. In cooperation with the esteemed Mrs. Engineer - Weronika Hebda, we also authored a scientific article published in \"Przegląd Geodezyjny nr 2/2023\"",
            onClick: navigateToTele,
        },
        {
            title: "Award for Student Activity by the Dean of MUT & Rector's Scholarship for Academic Excellence",
            path: "www.wojsko-polskie.pl > wat > en",
            description: "These recognitions, though not as grand as others, hold a special place in my heart. Education is a significant part of my life, making these awards as cherished as my other achievements.",
            onClick: navigateToScholar,
        },
        {
            title: "3rd Place in MUT's Rector Competition for Best Engineering Project",
            path: "https://wcy.wat.edu.pl/ > News > rector_competition_2021",
            description: "Awarded for the medical application \"PULSE\", developed in collaboration with Dr. Katarzyna Kanclerz. The project focused on accelerating the treatment and detection of depression.",
            onClick: navigateToRector,
        },
        {
            title: "Honorable mention in National Hackathon: \"Forum Wizja Rozwoju 2021\"",
            path: "https://2021.wizjarozwoju.pl/ > hackathon-wizja-rozwoju-21",
            description: "Recognized for developing an application aimed at enhancing safety and entertainment for adults and children on forest trails. This solution, developed in partnership with the Polish Forest District, focused on child safety during trips.",
            onClick: navigateToAgile,
        },

        {
            title: "1st place in National Hackathon: \"Smart Tourism Hackathon\"",
            path: "www.pcinn.org > smart-tourism-hackathon-inteligentne-szlaki",
            description: "\"In 2021, my team, CodeHussar, won with an application designed to maintain group cohesion during adventures on tourist trails, particularly in areas with limited connectivity. The application received endorsement and funding approval from PFRON, the Polish State Fund for the Rehabilitation of the Disabled.",
            onClick: navigateToReact,
        },
        {
            title: "2nd Place in European Hackathon: \"Hack Yeah 2020\"",
            path: "https://2020.hackyeah.pl > 2020 > the-finalist-default-name",
            description: "Competing in one of Europe's largest hackathons, HackYeah, I focused on a WWF task titled \"Zero Waste\". Our team developed \"Connet\", an IT system to track and rescue lost fishing nets in the Baltic Sea, aligning with my interest in wildlife and environmental conservation.",
            onClick: navigateToOffice,
        }
    ];

    return (
        <div className="resume">
            <Header
                currentPage="Awards"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="resume-content-with-sidebar">
                <div className="resume-content">
                    <div className="resume-stats">
                        About 7 important awards (with more expected)
                    </div>

                    {skillsItems.map((item, index) => (
                        <ExperienceItem
                            key={index}
                            title={item.title}
                            path={item.path}
                            description={item.description}
                            onClick={item.onClick}
                        />
                    ))}
                </div>
                <ProfileSidebar />
            </div>
            <div className="extra-skills-content">

                <div className="hire-me-section">
                    <div className="hire-me-title">Hire me</div>
                    <p>We could work together?</p>
                    <button onClick={showContact} className="hire-me-button">Contact</button>
                </div>
            </div>
        </div>
    );
}

function ExperienceItem({ title, path, description, onClick }) {
    // Split the path by '>'
    const pathParts = path.split(' > ');

    return (
        <div className="resume-item">
            <div className="resume-item-title" onClick={onClick}>
                {title}
            </div>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Awards;