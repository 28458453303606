
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Resume from './Resume';
import HomePage from './HomePage';
import Contact from "./Contact";
import Skills from "./Skills";
import Experience from "./Experience";
import Awards from "./Awards";
import Education from "./Education";
import Hobby from "./Hobby";
import Books from "./Books";
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/resume" element={<Resume />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/awards" element={<Awards />} />
                <Route path="/education" element={<Education />} />
                <Route path="/hobby" element={<Hobby />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/books" element={<Books />} />
            </Routes>
        </Router>
    );
}

export default App;