import React from 'react';
import './ProfileSidebar.css';
import ProfileImg from './profile3.jpg';
import BillGatesImg from './bill.jpg';
import ElonMuskImg from './elon.jpg';
import SteveJobsImg from './steve.jpg';

function ProfileSidebar() {

    const profileInfo = {
        name: "Iwo Lis",
        occupation: "IT Project Manager",
        occupation2: "Hired",
        location: "Warsaw, Poland",
        age: "23",
        height: "1.83 m (metric system only)",
        expertise: ["Information Systems Engineering", "Project Management"],
    };

    const relatedPeople = [
        { name: "Bill Gates", img: BillGatesImg, url: "https://www.google.com/search?q=bill+gates" },
        { name: "Elon Musk", img: ElonMuskImg, url: "https://www.google.com/search?q=elon+musk" },
        { name: "Steve Jobs", img: SteveJobsImg, url: "https://www.google.com/search?q=steve+jobs" },
    ];


    return (
        <aside className="profile-sidebar">
            <img src={ProfileImg} alt="Profile" className="profile-image" />
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">{profileInfo.occupation} based in {profileInfo.location}</p>
                <div className="profile-details">
                    <p><strong>Age : </strong> {profileInfo.age}</p>
                    <p><strong>Height : </strong> {profileInfo.height}</p>
                    <p><strong>Localization : </strong> <a href="https://www.google.com/maps/place/Lykkens+Minde+33,+2860+S%C3%B8borg,+Denmark/@55.7421879,12.4808891,17z/data=!3m1!4b1!4m6!3m5!1s0x465251e0ac07ed89:0xe322dccc691b826a!8m2!3d55.7421849!4d12.4834694!16s%2Fg%2F11l24bymrw?entry=ttu" target="_blank" rel="noopener noreferrer">Lykkens Minde 33, 2860 Søborg, Copenhagen, Denmark</a></p>
                    <p><strong>Occupation : </strong> {profileInfo.occupation2}</p>
                    <p><strong>Expertise : </strong> {profileInfo.expertise.join(", ")}</p>
                </div>
            </div>
            <div className="profile-related">
                <h2>People also search for</h2>
                <div className="related-people">
                    {relatedPeople.map(person => (
                        <div className="related-person" key={person.name}>
                            <a href={person.url} target="_blank" rel="noopener noreferrer">
                                <img src={person.img} alt={person.name} />
                                <span>{person.name}</span>
                            </a>
                        </div>
                    ))}
                </div>

            </div>
        </aside>
    );
}

export default ProfileSidebar;