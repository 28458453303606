import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebar';
import './Resume.css';
import './Header.css';
import './ProfileSidebar.css';
import {useNavigate} from "react-router-dom";


function Resume() {
    const navigate = useNavigate();
    function showContact() {
        navigate('/contact');
    }
    function showSkills() {
        navigate('/skills');
    }
    function showExperience() {
        navigate('/experience');
    }
    function showEducation() {
        navigate('/education');
    }
    function showPortfolio() {
        navigate('/awards');
    }
    function showHobby() {
        navigate('/hobby');
    }
    function showBooks() {
        navigate('/books');
    }
    const resumeItems = [
        {
            title: "Discover my unique skills",
            path: "www.best-it-employee.com > Skills",
            description: "Explore the skills driving my IT project management and programming success, blending technical expertise with strategic vision, shaping my path to a future CIO role...",
            onClick: showSkills
        },
        {
            title: "People who trusted me",
            path: "www.best-it-employee.com > Experience",
            description: "Watch my journey through various roles where trust and responsibility were key. This section highlights my collaborations, leadership experiences, and the impactful projects that have shaped my professional growth...",
            onClick: showExperience
        },
        {
            title: "Learning Odyssey",
            path: "www.best-it-employee.com > Education",
            description: "Once Albert Einstein said: \"Once you stop learning, you start dying.\" With this in mind, I will be learning as long as I can...",
            onClick: showEducation
        },
        {
            title: "See what others noticed in me",
            path: "www.best-it-employee.com > Awards",
            description: "Dive into my Awards section – a blend of serious coding, a dash of creativity, and a pinch of humor. Here, each project is more than just lines of code; they're results of hard work, creativity and epic teamwork...",
            onClick: showPortfolio
        },
        {
            title: "After Hours: The Fun Edition",
            path: "www.best-it-employee.com > Hobby",
            description: "Life isn't just about work. I delve into hobbies that not only fuel my passions but also sharpen skills essential for my professional growth...",
            onClick: showHobby
        },
        {
            title: "So much wisdom to be discovered within the cultural works",
            path: "www.best-it-employee.com > Books",
            description: "This is a few recommendations that really improved my knowledge about the problem solving, software developing and other life skills... Just have a look if you're interested in improving your skills...",
            onClick: showBooks
        }
    ];


    return (
        <div className="resume">
            <Header
                currentPage="Resume"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="main-content-container">
            <div className="resume-content-with-sidebar">
            <div className="resume-content">
                <div className="resume-stats">
                    About 10 000 000 000 000 cells (0.22 century)
                </div>

                {resumeItems.map((item, index) => (
                    <ResumeItem
                        key={index}
                        title={item.title}
                        path={item.path}
                        description={item.description}
                        onClick={item.onClick}
                    />
                ))}
            </div>
                <ProfileSidebar />
            </div>
            <div className="extra-content">
            <div className="search-related">
                <p className="search-related-title">Searches related to Iwo Lis</p>
                <a href="https://en.wikipedia.org/wiki/You_(Time_Person_of_the_Year)" target="_blank" rel="noopener noreferrer">Person of the year</a><br />
                <a href="https://en.wikipedia.org/wiki/Project_manager" target="_blank" rel="noopener noreferrer">IT Project manager</a><br />
                <a href="https://en.wikipedia.org/wiki/Chief_information_officer" target="_blank" rel="noopener noreferrer">CIO</a><br />
            </div>

            {/* Add hire me section here */}
            <div className="hire-me-section">
                <div className="hire-me-title">Hire me</div>
                <p>We could work together?</p>
                <button onClick={showContact} className="hire-me-button">Contact</button>
            </div>
            </div>
            </div>
        </div>
    );
}

function ResumeItem({ title, path, description, onClick }) {
    // Split the path by '>'
    const pathParts = path.split(' > ');

    return (
        <div className="resume-item">
            <div className="resume-item-title" onClick={onClick}>
                {title}
            </div>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Resume;