import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebarEducation';
import './Resume.css';
import './Header.css';
import './ProfileSidebarSkills.css';
import './Skills.css';
import './Education.css';
import {useNavigate} from "react-router-dom";



function Education() {
    const navigate = useNavigate();

    const navigateToDTU = () => {
        window.open ('https://www.dtu.dk/english/','_blank' );
    };
    const navigateToScholar = () => {
        window.open ('https://www.wojsko-polskie.pl/wat/en/', '_blank' );
    };
    const navigateToRector = () => {
        window.open ('https://21lo.waw.pl/','_blank' );
    };
    const navigateToAgile = () => {
        navigate('/books'); // Use the navigate function to change the route
    };
    const navigateToReact = () => {
        window.open ( 'https://www.actilingua.com/en/summer-school/','_blank' );
    };
    const navigateToOffice = () => {
        window.open ('https://off.org.pl/en/projects/mentoring-cruise/','_blank' );
    };
    function showContact() {
        navigate('/contact');
    }

    const skillsItems = [

        {

            title: "Technical University of Denmark",
            path: "www.dtu.dk > english",
            description: "Currently pursuing a Master's degree in Technology Entrepreneurship at the prestigious Technical University of Denmark (DTU), ranked number one in the EngiRank for engineering institutions. My choice to study here is driven by a desire to complement my IT knowledge with insights into business and management, bridging the gap between technology and entrepreneurship. This program offers a unique opportunity to engage with a diverse international community, allowing me to meet new people and immerse myself in different cultures, which I find both enriching and inspiring.",
            onClick: navigateToDTU,
        },
        {
            title: "Military University of Technology in Warsaw",
            path: "www.wojsko-polskie.pl > wat > en",
            description: "Here I discovered my true passion lies beyond the realms of conventional programming. Engaging deeply in system planning and client consultations, I found these aspects more invigorating and aligned with my skills. The monotonous routine of coding didn't quite match my zeal for dynamic financial strategies and interactive communication. This revelation steered my professional trajectory towards roles where I can leverage my strengths in strategic planning, client relations, and financial acumen, unveiling my potential in areas transcending traditional programming. Completed my education with a Bachelor of Engineering in Information Technology."
            ,
            onClick: navigateToScholar,

        },
        {
            title: "Mentoring Cruise from Future Foundation",
            path: "https://off.org.pl > en > projects > mentoring-cruise",
            description: "During my high school summer holidays I was selected for the prestigious Mentoring Cruise 2019, a program focused on management and business, including the Amazon Young Leaders Course. This exclusive opportunity was awarded to highly talented high school students based on a rigorous series of tests and academic performance evaluations.",
            onClick: navigateToOffice,
        },
        {
            title: "Vienna Summer School by ActiLingua",
            path: "https://www.actilingua.com > en > summer-school",
            description: "Participated in a Summer Language School in 2018, where I achieved an A2 level certification in German. This experience was not only academically enriching but also pivotal in developing my passion for travel and cultural exchange. Additionally, it provided an excellent opportunity to enhance my English language skills and fostered lasting friendships with a diverse international cohort.",
            onClick: navigateToReact,
        },
        {
            title: "Hugo Kołłątaj XXI High School in Warsaw",
            path: "https://21lo.waw.pl/",
            description: "Attended the prestigious XXI High School Hugo Kołłątaj in Warsaw, renowned for its excellence with a gold shield award from the 'Perspektywy' ranking, signifying its status as one of Poland’s top high schools. Specialized in IT & Mathematics, where I significantly advanced my knowledge in these fields, laying a strong foundation that proved beneficial even during my university studies. Additionally, I developed a keen interest in entrepreneurship, inspired by my favorite teacher, Mr. Zając, who instilled in us the value of erudition and its role in personal development.",
            onClick: navigateToRector,
        } ,
        {
            title: "And I learnt the rest in good old books",
            path: "https://best-it-employee.com > books",
            description: "Beyond the classroom, my learning was enriched by the timeless wisdom of books. They've been invaluable in complementing my formal education, reaffirming my belief in books as an enduring source of knowledge.",
            onClick: navigateToAgile,
        }
    ];

    return (
        <div className="resume">
            <Header
                currentPage="Education"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="resume-content-with-sidebar">
                <div className="resume-content">
                    <div className="resume-stats">
                        About 494 246 400 seconds of study (0.16 Century)
                    </div>

                        <div className="hire-me-section-edu">
                            <div className="hire-me-title-edu">Your University here</div>
                            <div className="hire-me-subtitle-edu"> Seize the unique opportunity to welcome not just a student, but a future pioneer who aims to be the best alumnus Your University has ever seen.</div>
                            <button onClick={showContact} className="hire-me-button-edu">Contact me and let's study together!</button>
                        </div>


                    {skillsItems.map((item, index) => (
                        <ExperienceItem
                            key={index}
                            title={item.title}
                            path={item.path}
                            description={item.description}
                            onClick={item.onClick}
                        />
                    ))}
                </div>
                <ProfileSidebar />
            </div>

        </div>
    );
}

function ExperienceItem({ title, path, description, onClick }) {
    // Split the path by '>'
    const pathParts = path.split(' > ');

    return (
        <div className="resume-item">
            <div className="resume-item-title" onClick={onClick}>
                {title}
            </div>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Education;