import React, { useState, useEffect } from 'react';
import './ProfileSidebarSkills.css';
// Import multiple images for the slideshow
import HobbyImg1 from './images/HobbyImg1.png';
import HobbyImg2 from './images/HobbyImg2.png';
import HobbyImg3 from './images/HobbyImg3.png';

function ProfileSidebar() {

    const profileInfo = {
        name: "Additional info",
        occupation: "Some interesting facts about my hobbies",
    };

    // Array of images for the slideshow
    const images = [HobbyImg1, HobbyImg2, HobbyImg3];

    // State to keep track of the current image index
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Change image every 5 seconds (5000 milliseconds)
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(currentImageIndex => (currentImageIndex + 1) % images.length);
        }, 10000); // Change image every 10000ms

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <aside className="profile-sidebar">
            <img src={images[currentImageIndex]} alt="Profile" className="profile-image" />
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">{profileInfo.occupation}</p>
                <div className="profile-details">
                    <p><strong>Number of sports trophies </strong> 29</p>
                    <p><strong>Most important trophy :</strong> Second place in Discgolf Junior Polish Champions </p>
                    <p><strong>Game of millennium : </strong> Mount and blade: warband </p>
                    <p><strong>Loved music genre : </strong> Any song can become a hit with the right idea. </p>
                    <p><strong>Favorite band: </strong> Gorillaz </p>
                    <p><strong>Most-liked movie of all time : </strong> Parasite </p>
                    <p><strong>Perfect animation : </strong> Shrek </p>
                    <p><strong>Ideal anime : </strong> Naruto </p>
                    <p><strong>Best day on the gym : </strong> Leg day</p>
                </div>
            </div>
        </aside>
    );
}

export default ProfileSidebar;