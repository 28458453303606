import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebarBooks';
import './Resume.css';
import './Header.css';
import './ProfileSidebarSkills.css';
import './Skills.css';
import book1 from './images/book11.png';
import book2 from './images/book2.jpg';
import book3 from './images/book3.jpg';
import book4 from './images/book4.jpg';
import book5 from './images/book5.jpg';
import book6 from './images/book6.jpg';
import {useNavigate} from "react-router-dom";


function Books() {
    const navigate = useNavigate();

    const navigateToTele = () => {
        window.open ('https://www.amazon.com/Short-History-Nearly-Everything/dp/076790818X','_blank' );
    };
    const navigateToScholar = () => {
        window.open ('https://www.amazon.com/Crossing-Chasm-3rd-Disruptive-Mainstream/dp/0062292986', '_blank' );
    };
    const navigateToRector = () => {
        window.open ('https://www.amazon.com/Burn-Math-Class-Reinvent-Mathematics/dp/0465053734','_blank' );
    };
    const navigateToAgile = () => {
        window.open ('https://www.amazon.com/Project-Management-Unofficial-Manager-Updated-dp-1637740506/dp/1637740506/ref=dp_ob_title_bk','_blank' );
    };
    const navigateToReact = () => {
        window.open ( 'https://www.amazon.pl/Lord-Rings-Trilogy-J-R-R-Tolkien/dp/0261103253','_blank' );
    };
    const navigateToOffice = () => {
        window.open ('https://www.amazon.com/Steve-Jobs-Walter-Isaacson/dp/1451648537','_blank' );
    };
    function showContact() {
        navigate('/contact');
    }

    const booksItems = [


        {
            title: "A Short History of Nearly Everything",
            path: "www.amazon.com > Short-History-Nearly-Everything",
            author: "Bill Bryson",
            year: "2004",
            description: "Embarking on this read was like a thrilling expedition through scientific discoveries. Bryson demystifies complex concepts with wit, making the journey from the Big Bang to human evolution not just enlightening but utterly engaging. It's a must-read for anyone curious about...",
            imageUrl: book1, // Ensure this is the correct path
            onClick: navigateToTele, // Update with the correct path
        },
        {
            title: "Burn Math Class: And Reinvent Mathematics for Yourself",
            path: "www.amazon.com > Burn-Math-Class-Reinvent-Mathematics",
            author: "Jason Wilkes",
            year: "2016",
            description: "\"Imagine a book that transforms the so-called 'complicated' phrases of mathematics into a thrilling adventure through numbers and logic. This guide is crafted specifically for young minds eager to conquer the world of math but find themselves daunted by its complex terminology...",
            imageUrl: book2, // Ensure this is the correct path
            onClick: navigateToRector, // Update with the correct path
        },
        {
            title: "Steve Jobs",
            path: "www.amazon.com > Steve-Jobs-Walter-Isaacson",
            author: "Walter Isaacson",
            year: "2011",
            description: "Walter Isaacson’s biography of Steve Jobs is a compelling journey through the life of a tech visionary. It's an essential read for future IT entrepreneurs, offering lessons in innovation and perseverance. I highly recommend it for its insights into Jobs' relentless drive and spirit...",
            imageUrl: book3, // Ensure this is the correct path
            onClick: navigateToOffice, // Update with the correct path
        },
        {
            title: "Project Management for the Unofficial Project Manager",
            path: "www.amazon.com > Project-Management-Unofficial-Manager-Updated",
            author: "Kory Kogon & Suzette Blakemore",
            year: "2015",
            description: "This guide provided me with foundational management skills through practical advice on leadership, organization, and communication. Its clear, actionable strategies are invaluable for anyone aiming to successfully navigate project management, enhancing my ability to lead...",
            imageUrl: book4, // Ensure this is the correct path
            onClick: navigateToAgile, // Update with the correct path
        },
        {
            title: "Crossing the Chasm, Marketing and Selling Disruptive Products to Mainstream Customers",
            path: "www.amazon.com > Crossing-Chasm-3rd-Disruptive-Mainstream",
            author: "Geoffrey A. Moore",
            year: "2014",
            description: "Offers crucial insights on marketing disruptive products to mainstream customers, teaching strategies to bridge the early adopter-market gap. Essential for tech entrepreneurs, it equipped me with tactics for broader market acceptance, enhancing my understanding of market...",
            imageUrl: book5, // Ensure this is the correct path
            onClick: navigateToScholar, // Update with the correct path
        },
        {
            title: "The Lord of the Rings",
            path: "www.amazon.com > Lord-Rings-Trilogy",
            author: "John Ronald Reuel Tolkien",
            year: "1954",
            description: "This is my favorite fantasy series, where epic adventures and timeless courage meet. It's not just a guilty pleasure but a wellspring of inspiration, sprinkled with humor at the thought that Tolkien crafted an entire language for the elves before deciding, 'Why not write a book about it?'",
            imageUrl: book6, // Ensure this is the correct path
            onClick: navigateToReact, // Update with the correct path
        }
    ];
    console.log('Books Items:', booksItems);

    return (
        <div className="resume">
            <Header
                currentPage="Books"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="resume-content-with-sidebar">
                <div className="resume-content">
                    <div className="resume-stats">
                        About 101,000,000 pages read (a lot of books)
                    </div>

                    {booksItems.map((item, index) => (
                        <BooksItem
                            key={index}
                            title={item.title}
                            path={item.path}
                            description={item.description}
                            author={item.author} // Make sure this is added
                            year={item.year} // Make sure this is added
                            imageUrl={item.imageUrl} // Make sure this is added
                            onClick={item.onClick}
                        />
                    ))}
                </div>
                <ProfileSidebar />
            </div>
            <div className="extra-skills-content">

                <div className="hire-me-section">
                    <div className="hire-me-title">Sounds interesting?</div>
                    <p>Interested in collaboration or just in sharing a passion for books?</p>
                    <button onClick={showContact} className="hire-me-button">Contact me</button>
                </div>
            </div>
        </div>
    );
}
function BooksItem({ title, path, description, onClick, author, year, imageUrl }) {
    // This function will be called when the title, author, or year is clicked
    const handleNavigation = (e) => {
        e.preventDefault(); // Prevent the default anchor behavior
        onClick(); // Call the onClick function passed in props
    };

    return (
        <div className="book-item">
            <div className="book-title" onClick={handleNavigation}>{title}</div>
            <div className="book-details-container">
                <img src={imageUrl} alt={title} className="book-image" onClick={handleNavigation} />
                <div className="book-text-details">
                    <a href="#" onClick={handleNavigation} className="book-path">{path}</a>
                    <a href="#" onClick={handleNavigation} className="book-author-year">{author} - {year}</a>
                    <div className="book-description">{description}</div>
                </div>
            </div>
        </div>
    );
}

export default Books;