import React, { useState, useEffect } from 'react';
import './ProfileSidebarEducation.css';
import aalborg from './images/map5.png';
import dtu from './images/map1.png';
import man from './images/map2.png';
import aarhus from './images/map3.png';
import { Link } from 'react-router-dom';


function ProfileSidebar() {
    const profileInfo = {
        name: "Want to discuss a science project?",
        occupation: "Need IT expertise for your team? Contact me to explore project development opportunities.",
    };

    // Slideshow images and URLs
    const slides = [
        { src: dtu, url: 'https://www.google.com/maps/dir/Warsaw/Technical+University+of+Denmark,+Bygning,+Lyngby,+Denmark/@54.1100678,14.1273333,7z/data=!3m2!4b1!5s0x479e75ef658f982f:0x39f30e5569ba54fc!4m14!4m13!1m5!1m1!1s0x471ecc669a869f01:0x72f0be2a88ead3fc!2m2!1d21.0122287!2d52.2296756!1m5!1m1!1s0x46524e6328b8bd5d:0xcf045cde0449c6c5!2m2!1d12.5233698!2d55.7860512!3e2?entry=ttu' }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(timer);
    }, [slides.length]);

    return (
        <aside className="profile-sidebarEdu">
            <div className="map-slideshow">
                {slides.map((slide, index) => (
                    <a key={index} href={slide.url} target="_blank" rel="noopener noreferrer">
                        <img
                            src={slide.src}
                            alt={`Map ${index + 1}`}
                            style={{ display: index === currentSlide ? 'block' : 'none' }}
                        />
                    </a>
                ))}
            </div>
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">
                    Need IT expertise for your team?
                    <Link to="/contact" className="contact-link"> Contact me </Link>
                    to explore project development opportunities.
                </p>
                <div className="profile-details">
                    <p><strong>Most important aspect of school : </strong> Teaching staff & other students</p>
                    <p><strong>Favorite quotation : </strong> I know that I know nothing ~ Socrates </p>
                    <p><strong>Favorite mnemonic technique : </strong> Story creation </p>
                    <p><strong>Best measurement system : </strong> Metric system (accept no substitutes!)</p>
                </div>
            </div>
        </aside>
    );
}

export default ProfileSidebar;