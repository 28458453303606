import React from 'react';
import MyLogo from './MyLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

function Header({ currentPage }) {

    const navItems = ["Resume", "Skills", "Experience" , "Awards", "Education", "Hobby", "Contact"];
    const searchSequence = currentPage === "Contact" ?
        ["Contact with best IT employee", 2000, () => {}] :
        [currentPage, 1000, () => {}];

    return (
        <div className="header">
            <div className="header-top">
                <Link to="/">
                <img src={MyLogo} alt="Logo" className="header-logo" />
                </Link>
                <div className="header-search-bar">
                    <TypeAnimation
                        cursor={true}
                        sequence={searchSequence}
                        wrapper="div"
                        className="header-search-input"
                        repeat={Infinity}
                    />
                    <button className="header-search-button">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                </div>
            </div>
            <div className="header-navigation-bar">
                {navItems.map(item => (
                    <div key={item} className={`nav-item ${currentPage === item ? "active" : ""}`}>
                        <Link to={`/${item.toLowerCase()}`}>{item}</Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Header;
