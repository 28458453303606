import React from 'react';
import './ProfileSidebarSkills.css';
import ProfileImg from './profile.jpg';


function ProfileSidebar() {

    const profileInfo = {
        name: "Additional info",
        occupation: "Some interesting facts about my skills",
    };


    return (
        <aside className="profile-sidebar">
            <img src={ProfileImg} alt="Profile" className="profile-image" />
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">{profileInfo.occupation}</p>
                <div className="profile-details">
                    <p><strong>Favorite programing language : </strong> Java 17</p>
                    <p><strong>My most useless skill for office :</strong> acrobatics </p>
                    <p><strong>Favorite agile methodology : </strong> Scrumban </p>
                    <p><strong>Go-To debug strategy : </strong> "Pretend You're Teaching" Technique</p>
                </div>
            </div>
        </aside>
    );
}

export default ProfileSidebar;