import React from 'react';
import './ProfileSidebarSkills.css';
import ProfileImg from './images/AwardsImg.jpg';


function ProfileSidebar() {

    const profileInfo = {
        name: "Additional info",
        occupation: "Some interesting facts about my dreams & goals",
    };


    return (
        <aside className="profile-sidebar">
            <img src={ProfileImg} alt="Profile" className="profile-image" />
            <div className="profile-info">
                <h1 className="profile-name">{profileInfo.name}</h1>
                <p className="profile-detail">{profileInfo.occupation}</p>
                <div className="profile-details">
                    <p><strong>Current Goal : </strong> PhD in IT and Business</p>
                    <p><strong>Long-Term Goal :</strong> Become the CIO of famous company </p>
                    <p><strong>Dream side-objective : </strong> Publish a book or an animation </p>
                    <p><strong>Favorite Childhood Dream : </strong> Become a wizard and cure cancer</p>
                </div>
            </div>
        </aside>
    );
}

export default ProfileSidebar;