import React from 'react';
import Header from './Header';
import ProfileSidebar from './ProfileSidebarHobby';
import './Resume.css';
import './Header.css';
import './ProfileSidebarSkills.css';
import './Skills.css';
import {useNavigate} from "react-router-dom";


function Hobby() {
    const navigate = useNavigate();

    const navigateToTele = () => {
        window.open ('https://www.fivb.com/en/beachvolleyball','_blank' );
    };
    const navigateToScholar = () => {
        window.open ('https://juggercouncil.org/en/', '_blank' );
    };
    const navigateToRector = () => {
        window.open ('https://www.instagram.com/centaurywarszawa/','_blank' );
    };
    const navigateToAgile = () => {
        window.open ('https://www.snow-forecast.com/','_blank' );
    };
    const navigateToReact = () => {
        window.open ( 'https://discgolf.com/disc-golf-education-development/how-to-play-disc-golf/','_blank' );
    };
    const navigateToOffice = () => {
        window.open ('https://www.britannica.com/sports/table-tennis','_blank' );
    };
    const navigateToRp = () => {
        window.open ('https://dnd.wizards.com/how-to-play','_blank' );
    };
    const navigateToGames = () => {
        window.open ('https://www.pcgamesn.com/the-15-best-indie-games-on-pc','_blank' );
    };
    const navigateToMovies = () => {
        window.open ('https://collider.com/best-screenplays-all-time-ranked/#39-bicycle-thieves-39-1948','_blank' );
    };
    function showContact() {
        navigate('/contact');
    }

    const hobbyItems = [


        {
            title: "Best team sport - Volleyball",
            path: "www.fivb.com > en > beachvolleyball",
            description: "Probably my favorite team sport, which I've dedicated the most time to. I've participated in numerous tournaments with my team. I particularly enjoy both traditional and beach volleyball, each having its own distinct set of rules.",
            onClick: navigateToTele,
        },
        {
            title: "Ultimate test of quick thinking - Jugger",
            path: "https://juggercouncil.org > en",
            description: "One of my favorite lesser-known team sports is Jugger – a game that demands quick thinking, inventive strategy, robust physical condition, and sharp reflexes. The rules can be found in the link above, but in essence, it's a unique blend of rugby and combat with melee weapons. Interestingly, there's one player, known as the 'quick,' who runs and leads without a weapon. Can you guess what my favorite position is?",
            onClick: navigateToScholar,
        },
        {
            title: "Hitting ball with 100000 rules - Baseball",
            path: "https://www.instagram.com > centaurywarszawa",
            description: "Baseball, a sport less common in Europe compared to its popularity in America, is one of my favorites. I play as an outside hitter for Centaury Warsaw, relishing the unique challenges and opportunities this role provides to significantly contribute to my team's success. The emphasis on teamwork and strategy in baseball particularly appeals to me. Being part of a sport that's not mainstream in Europe, especially as a member of Centaury Warsaw, adds an extra layer of excitement and passion to the game for me.",
            onClick: navigateToRector,
        },
        {
            title: "Creative way of visiting mountains - Skiing",
            path: "https://www.snow-forecast.com/",
            description: "Skiing, a sport that offers both thrill and serenity, is one of my cherished pastimes. Gliding down snowy slopes, I find a unique blend of adrenaline and peace. It's a sport that demands skill, balance, and a deep connection with the natural environment. Unlike more team-oriented sports, skiing provides a personal challenge, where the competition is often with oneself to improve and excel. It's a passion that allows me to explore the beautiful winter landscapes, often leading me to breathtaking locations far from the bustling city life.",
            onClick: navigateToAgile,
        },

        {
            title: "Better golf - discgolf",
            path: "https://discgolf.com > education-development > how-to-play-disc-golf",
            description: "Disc golf, an exciting and rapidly growing sport, has become one of my favorite activities. It's like a revamped, more dynamic version of traditional golf, offering an accessible and inexpensive way to enjoy the outdoors. The beauty of disc golf lies not just in the game itself, but in the opportunity it presents to explore various parks and scenic locations. Each course brings its own unique challenge, blending sport with the discovery of new environments. For me, it's the perfect way to combine a love for competition with the joys of being in nature, making it a uniquely satisfying experience.",
            onClick: navigateToReact,
        },
        {
            title: "Never mix up rocket with racket - table tennis",
            path: "https://www.britannica.com > sports > table-tennis",
            description: "Table tennis, a fast-paced and engaging sport, is a favorite of mine. It's not only a test of reflexes and precision but also a fun and accessible way to stay active. Playing table tennis offers an exciting blend of strategy and skill, perfect for quick games that are both competitive and enjoyable. This sport has been a great way to unwind and challenge myself, all while enjoying the simple pleasure of a back-and-forth rally.",
            onClick: navigateToOffice,
        },
        {
            title: "World of imagination - role-playing games",
            path: "https://dnd.wizards.com > how-to-play",
            description: "Role-playing games hold a special place in my hobbies, particularly as I often take on the role of the Dungeon Master (DM). This role allows me to unleash my creativity in crafting intricate stories and captivating narratives. I relish the opportunity to guide players through these adventures, blending imagination with strategy to create a unique and immersive experience. Being a DM is not just about storytelling; it's about creating a world where players can explore, interact, and shape the story's outcome. ",
            onClick: navigateToRp,
        },
        {
            title: "Connecting with friends from home - gaming",
            path: "www.pcgamesn.com > the-15-best-indie-games-on-pc",
            description: "Video gaming is a key interest of mine, with a particular fondness for strategic and indie titles. Games like 'Sid Meier's Civilization VI' captivate me with their deep strategic layers, challenging me to think several steps ahead. I'm also drawn to the unpredictability and replayability of rogue-likes, with 'Caveblazers' being a standout favorite. My appreciation for indie games stems from their innovative gameplay and storytelling, which often eclipse the need for high-end graphics. For me, the essence of gaming lies in engaging mechanics and immersive experiences, not just visual appeal.",
            onClick: navigateToGames,
        },
        {
            title: "Way of creating - scriptwriting",
            path: "https://collider.com > best-screenplays-all-time",
            description: "Scriptwriting, for me, is a fascinating extension of my love for role-playing games and movies. While the latter might seem an obvious influence, it's the intricate storytelling and character development in RPGs that truly inspire my scripts. This hobby isn't just about weaving narratives; it's a step towards fulfilling a dream – to one day see my stories come alive in a published movie or book. The art of scriptwriting allows me to explore diverse worlds and complex characters, bridging the gap between imagination and reality.",
            onClick: navigateToMovies,
        }
    ];

    return (
        <div className="resume">
            <Header
                currentPage="Hobby"
                headerClass="header"
                searchBarClass="header-search-bar"
                inputClass="header-search-input"
            />
            <div className="resume-content-with-sidebar">
                <div className="resume-content">
                    <div className="resume-stats">
                        About "Gogol" of various interests
                    </div>

                    {hobbyItems.map((item, index) => (
                        <HobbyItem
                            key={index}
                            title={item.title}
                            path={item.path}
                            description={item.description}
                            onClick={item.onClick}
                        />
                    ))}
                </div>
                <ProfileSidebar />
            </div>
            <div className="extra-skills-content">

                <div className="hire-me-section">
                    <div className="hire-me-title">Anything sounds interesting?</div>
                    <p>I am always into talking about hobbies</p>
                    <button onClick={showContact} className="hire-me-button">Contact</button>
                </div>
            </div>
        </div>
    );
}

function HobbyItem({ title, path, description, onClick }) {
    // Split the path by '>'
    const pathParts = path.split(' > ');

    return (
        <div className="resume-item">
            <div className="resume-item-title" onClick={onClick}>
                {title}
            </div>
            <div className="resume-item-path">
                {pathParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && ' > '}
                        <a href="" onClick={onClick} className="path-part">{part}</a>
                    </React.Fragment>
                ))}
            </div>
            <div className="resume-item-description">{description}</div>
        </div>
    );
}

export default Hobby;